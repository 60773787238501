import { Link } from "react-router-dom";

const EventsListContent = [
	{
		image: "testersDayPartyEvent",
		path: "/events/testers-day-party",
		title: "Tester's Day Party",
		description: "Let's celebrate Tester's Day!",
		date: "09/87/2024",
	},
	{
		image: "QASummerCampEvent",
		path: "/events/qa-summer-camp",
		title: "QA Summer Camp",
		description: "Something exciting is coming! Our QA Summer Camp is on the way!",
		date: "08/07/2024",
	},
	{
		image: "discountedExamsEvent",
		path: "/events/discount-on-istqb-exams",
		title: "10% Discount for all ISTQB exams on 30th of July",
		description:
			"We are excited to announce the upcoming ISTQB certification exam, scheduled for July 30.",
		date: "08/07/2024",
	},
	{
		image: "exams2024Event",
		path: "/events/istqb-certification-exams-of-2024",
		title: "ISTQB certification exams of 2024",
		description: "Begin the upcoming year by attaining a fresh accomplishment",
		date: "15/01/2024",
	},
];

export default function EventsList({ column, stylevariation, item }) {
	const list = EventsListContent.slice(0, item);
	return (
		<>
			{list.map((value, index) => (
				<div className={`${column}`} key={index}>
					<div className={`portfolio ${stylevariation}`}>
						<div className="thumbnail-inner">
							<div className={`thumbnail ${value.image}`}></div>
							<div className={`bg-blr-image ${value.image}`}></div>
						</div>
						<div className="content">
							<div className="inner">
								<h4>
									<a href={value.path}>{value.title}</a>
								</h4>
								<p>{value.date}</p>
								<p
									style={{
										textOverflow: "ellipsis",
										overflow: "hidden",
										display: "-webkit-box",
										WebkitLineClamp: 3,
										WebkitBoxOrient: "vertical",
									}}
								>
									{value.description}
								</p>
								<div className="portfolio-button">
									<a className="rn-btn" href={value.path}>
										View Details
									</a>
								</div>
							</div>
						</div>
						<Link className="link-overlay" to={value.path}></Link>
					</div>
				</div>
			))}
		</>
	);
}
